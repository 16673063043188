





































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NoticiasComponent extends Vue {
  private noticias: Array<any> = [];

  private created() {
    this.getNoticias();
  }

  // NOTICIAS
  private getNoticias() {
    // this.$q.loading.show({
    //   // delay: 100, // ms
    // });
    this.$axios
      .get("noticias/destacadas")
      .then((res) => {
        // console.log(res);
        // console.log("noticias destacas", res.data);
        let base = process.env.VUE_APP_BASE_URL;
        // let base = "https://docs.quilpue.cl/";

        if (this.$q.screen.xs || this.$q.screen.sm) {
          for (let i = 0; i < 2; i++) {
            this.noticias.push(res.data[i]);
            // console.log(this.noticias);
          }
        } else {
          this.noticias = res.data;
        }
        for (let i = 0; i < this.noticias.length; i++) {
          if (
            this.noticias[i].fotos.image_intro &&
            this.noticias[i].fotos.image_intro != ""
          ) {
            this.noticias[i].fotos.image_intro =
              base + this.noticias[i].fotos.image_intro;
          } else {
            if (this.noticias[i].fotos.image_fulltext) {
              this.noticias[i].fotos.image_intro =
                base + this.noticias[i].fotos.image_fulltext;
            } else if (this.$q.screen.xs || this.$q.screen.sm) {
              this.noticias[0].fotos.image_intro = require("@/assets/logos/logo300.webp");
            } else {
              this.noticias[0].fotos.image_intro = require("@/assets/banner_mobile.webp");
            }
            // this.noticias[
            //   i
            // ].fotos.image_intro = require("@/assets/logos/logo300.webp");
          }
        }
        // this.$q.loading.hide();
      })
      .catch((err: any) => {
        // this.$q.loading.hide();
      });
  }

  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { nombre: nombreWeb },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  private fechaPublicacionNoticias(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + ", " + anhoInicio;
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
}
